/* src/App.css */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}
header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}
footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.App-header,
.App-footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 1em;
}

.App-layout {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  flex: 1;
}

.App-sidebar-left,
.App-sidebar-right {
  background-color: #f4f4f4;
  padding: 1em;
}

.App-content {
  padding: 1em;
}

.ad {
  background-color: #ddd;
  border: 1px solid #ccc;
  padding: 1em;
  text-align: center;
}

.App-footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 1em;
}

nav {
  background-color: #f8f8f8;
  padding: 1em;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 1em;
}

nav ul li a {
  text-decoration: none;
  color: #61dafb;
}

nav ul li a:hover {
  text-decoration: underline;
}
